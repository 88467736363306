import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import engenheiro from "../images/engenheiro.jpg"
import tools from "../images/tools.png"
import usinando from "../images/usinando.png"
import injection from "../images/injection.png"
import project from "../images/project.png"
import { Link } from "gatsby"

function Sobre() {
  return (
    <Layout
      aboveFooter
      loading={false}
      titlePage="A Empresa"
      titleMargin="44"
      contactButton
    >
      <Seo
        keywords={[`Inovação`, `Usinagem`, `Ferramentaria`]}
        title="A Empresa"
      />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-20 lg:gap-8 my-24 self-center">
        <div>
          <div>
            <img className="lg:w-full mb-16" src={engenheiro} />
            <h2 id="nossahistoria" className="mb-3">
              Nossa história
            </h2>
            <p name="a" className="text-lg mb-6 opacity-75">
              A inovação usinagem nasce do espírito empreendedor de seus
              fundadores em oferecer serviços de qualidade em usinagem, atuando
              em parcerias solidificadas com clientes e fornecedores.
            </p>
          </div>
        </div>
        <div>
          <h3 id="missao" className="mb-3">
            Missão
          </h3>
          <div>
            <p name="a" className="text-lg mb-6 opacity-75">
              Ser empresa de referência em projetar, fabricar ferramentas e prestar serviços de usinagem gerando a criação de valor.
            </p>
          </div>
          <br />
          <br />
          <h3 id="visao" name="visao" className="mb-3">
            Visão
          </h3>
          <div>
            <p name="a" className="text-lg mb-6 opacity-75">
              Ser Reconhecida como fornecedor de soluções em ferramentas e usinagem técnica.
            </p>
          </div>
          <br />
          <br />
          <h3 id="valores" name="valores" className="mb-3">
            Valores
          </h3>
          <div>
            <p name="a" className="text-lg mb-6 opacity-75">
              Fé, Transparência, Trabalho em Equipe, Compromisso e Inovação.
            </p>
          </div>
        </div>
      </div>
      <div>
        <h2 className="text-center mt-24 mb-20">Áreas de atuação</h2>
        <div className="grid grid-cols-1 md:grid-cols-4 text-center mb-10">
          <center>
            <img className="h-16" src={tools} />
            <h3>Ferramentas</h3>
          </center>
          <center>
            <img className="h-16" src={usinando} />
            <h3>Usinagem</h3>
          </center>
          <center>
            <img className="h-16" src={injection} />
            <h3>Modelação</h3>
          </center>
          <center>
            <img className="h-16" src={project} />
            <h3>Projetos especiais</h3>
          </center>
        </div>
      </div>

      <center className="mt-32">
        <Link to="/estrutura">
          <h3>Clique para conhecer nossas máquinas.</h3>
        </Link>
      </center>
    </Layout>
  )
}

export default Sobre
